<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card-text class="py-6">
          <v-container>
            <p>
              <span class="red--text" v-if="!isOwner">
                Apenas a empresa criadora do pedido pode editar.
              </span>
            </p>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
                v-if="!empresaAtual.gate"
              >
                <v-col cols="12" sm="12" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="gate"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Gate/Unidade Destino"
                      required
                      :items="gates"
                      v-model="pedido.gate"
                      :item-text="getLabelEmpresa"
                      item-value="public_id"
                      return-object
                      :error-messages="errors"
                      @change="setFornecedorPedido"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="estado">
                    <v-text-field
                      label="U.F"
                      v-model="pedido.gate.state"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="município">
                    <v-text-field
                      label="Município"
                      v-model="pedido.gate.city"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="10"
                class="d-md-flex justify-center mb-0 pb-0"
              >
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="data_inicio"
                    rules="required"
                  >
                    <v-text-field
                      v-model="pedido.data_inicio"
                      label="Data Inicio"
                      required
                      :error-messages="errors"
                      type="date"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="data_fim"
                    rules="required"
                  >
                    <v-text-field
                      v-model="pedido.data_fim"
                      label="Data Fim"
                      required
                      :error-messages="errors"
                      type="date"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider
                    v-if="!temPedidoASerEditado"
                    v-slot="{ errors }"
                    name="fornecedor"
                    rules="required"
                  >
                    <v-autocomplete
                      v-if="empresaAtual.gate"
                      label="Fornecedor"
                      required
                      :items="fornecedores"
                      v-model="pedido.fornecedor"
                      :item-text="getLabelEmpresa"
                      item-value="public_id"
                      return-object
                      :error-messages="errors"
                      :disabled="!empresaAtual.gate"
                      :readonly="!empresaAtual.gate"
                      @change="handleFornecedorSelecionado"
                    />
                    <v-text-field
                      v-else
                      label="Fornecedor"
                      disabled
                      v-model="pedido.fornecedor.trade_name"
                    />
                  </validation-provider>
                  <v-text-field
                    v-else
                    label="Fornecedor"
                    v-model="pedido.fornecedor.trade_name"
                    disabled
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="estado">
                    <v-text-field
                      label="U.F"
                      v-model="pedido.fornecedor.state"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="município">
                    <v-text-field
                      label="Município"
                      v-model="pedido.fornecedor.city"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
              >
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="tipo de frete"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Tipo de frete"
                      required
                      :items="tiposFrete"
                      v-model="pedido.tipoFrete"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="navio">
                    <v-autocomplete
                      label="Navio"
                      :items="naviosComOpcaoNenhum"
                      v-model="pedido.navio"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                      @change="getDistribuicoes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="nº pedido importação"
                    :rules="{ required: navioFoiSelecionado }"
                  >
                    <v-text-field
                      v-model="pedido.numero_pedido_importacao"
                      label="Nº Pedido Importação"
                      title="Número do pedido da importação"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="produto"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Produto"
                      required
                      :items="produtos"
                      v-model="pedido.produto"
                      item-text="descricao"
                      item-value="id"
                      return-object
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="sub produto"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Sub Produto"
                      required
                      :items="subProdutos"
                      v-model="pedido.subProduto"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="embalagem"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Embalagem"
                      required
                      :items="embalagens"
                      v-model="pedido.embalagem"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
              >
                <v-col cols="6" sm="6" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="tolerância mínima"
                    rules="numValid"
                  >
                    <v-text-field
                      type="number"
                      min="0"
                      max="100"
                      label="Tolerância (%) Para Abaixo (Ton)"
                      v-model="pedido.tolerancia_min"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" sm="6" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="tolerância máxima"
                    rules="numValid"
                  >
                    <v-text-field
                      type="number"
                      min="0"
                      max="100"
                      label="Tolerância (%) Para Cima (Ton)"
                      v-model="pedido.tolerancia_max"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Tipo de Operação"
                    rules="required"
                  >
                    <v-autocomplete
                      label="Tipo de Operação"
                      item-text="descricao"
                      required
                      item-value="id"
                      :items="tiposOperacao"
                      v-model="pedido.tipo_operacao"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
              >
                <v-col cols="6" sm="6" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="quantidade"
                    rules="required"
                  >
                    <v-text-field
                      type="number"
                      label="Quantidade Total (Ton)"
                      v-model="pedido.quantidade"
                      :rules="rules"
                      :min="pedidoASerEditado.quantidade_usada"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" sm="6" md="4">
                  <v-text-field
                    type="number"
                    label="Quantidade Usada (Ton)"
                    v-model="pedidoASerEditado.quantidade_usada"
                    :disabled="true"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <buttons-cadastro
            @close="close"
            @save="save"
            :disabled="invalid || !isOwner"
          />
        </v-card-actions>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ButtonsCadastro from '../../ButtonsCadastro/ButtonsCadastro.vue'
import GateAPI from '@/services/tenants/gates'
import DistribuicaoAPI from '@/services/distribuicao'

export default {
  name: 'EditarPedido',

  components: { ButtonsCadastro },

  props: {
    pedidoASerEditado: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      overlay: false,
      delay: 300,

      showDistribuicoes: false,
      headersDistribuicoes: [
        {
          text: 'Terminal',
          align: 'start',
          value: 'terminal.descricao',
        },
        { text: 'Fornecedor', value: 'fornecedor.trade_name' },
        { text: 'Cliente', value: 'cliente.trade_name' },
        { text: 'Munícipio', value: 'cidade' },
        { text: 'D.I.', value: 'di' },
        { text: 'N. Pedido Imp.', value: 'nPedidoImp' },
        { text: 'Shipment', value: 'shipment' },
        { text: 'Produto', value: 'produto.descricao' },
        { text: 'Sub Produto', value: 'subProduto.descricao' },
        { text: 'Quantidade (TON)', value: 'quantidade' },
        { text: 'Restante', value: 'quantidade_restante' },
      ],
      distribuicoes: [],
      embalagens: [],
      produtos: [],
      subProdutos: [],
      navios: [],
      gates: [],

      pedido: {
        data: '',
        fornecedor: {},
        gate: {},
      },
    }
  },

  computed: {
    ...mapState('patio', ['tiposFrete', 'tiposOperacao']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    isOwner() {
      return this.isProprietario() || this.isGate()
    },
    fornecedoresGate() {
      return this.gate.fornecedores || []
    },

    naviosComOpcaoNenhum() {
      return [{ descricao: 'Nenhum', id: null }, ...this.navios]
    },

    navioFoiSelecionado() {
      return typeof this.pedido.navio === 'number' && null >= 0
    },

    temPedidoASerEditado() {
      return Object.keys(this.pedidoASerEditado).length > 0
    },
    rules() {
      return [
        (v) => !!v || 'Obrigatório!',
        (v) =>
          v >= this.pedidoASerEditado.quantidade_usada ||
          `Valor mínimo deve ser ${this.pedidoASerEditado.quantidade_usada}.`,
      ]
    },
  },

  async created() {
    if (!this.empresaAtual.gate) {
      this.gates = await GateAPI.getByParams({
        fornecedor_tenant: this.empresaAtual.public_id,
      })
    } else {
      await this.getFornecedores()
    }

    const today = new Date().toLocaleDateString('pt-BR')
    const [dia, mes, ano] = today.split('/')

    this.pedido.data = `${ano}-${mes}-${dia}`

    await this.getTiposFrete()
    await this.getTipoOperacao()

    await this.setValoresIniciaisDoPedido(this.pedidoASerEditado)
    this.pedido.numero_pedido = this.pedidoASerEditado.numero_pedido
    // if (this.temPedidoASerEditado) {
    //   this.isEdit = true

    //   await this.setValoresIniciaisDoPedido(this.pedidoEdit)
    // }
  },

  methods: {
    ...mapActions('patio', [
      'addPedido',
      'getTiposFrete',
      'getTipoOperacao',
      'getNavios',
      'editPedido',
    ]),
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    isProprietario() {
      return this.pedido.proprietario
        ? this.pedido.proprietario.public_id == this.empresaAtual.public_id
        : true
    },
    isGate() {
      return this.pedido.gate
        ? this.pedido.gate.public_id == this.empresaAtual.public_id
        : true
    },
    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    async setFornecedorPedido() {
      const fornecedor = this.pedido.gate.fornecedores.find(
        (f) => f.fornecedor_id === this.empresaAtual.public_id
      )
      this.pedido.fornecedor = fornecedor
      await this.setOpcoesDoFornecedor(fornecedor)
    },

    async setOpcoesDoFornecedor(fornecedor) {
      this.produtos = fornecedor.produtos
      this.embalagens = fornecedor.embalagens
      this.navios = await this.getNavios({
        tenant: fornecedor.fornecedor_id,
      })
      //   this.gates = await GateAPI.getByParams({
      //     fornecedor: fornecedor.public_id,
      //   })
    },

    async setValoresIniciaisDoPedido(pedido) {
      const {
        fornecedor,
        gate,
        tipoFrete,
        navio,
        subProduto,
        embalagem,
        quantidade,
      } = pedido

      await this.setOpcoesDoFornecedor(fornecedor)

      this.pedido = {
        ...pedido,
        quantidade,
        gate,
        tipoFrete: tipoFrete.id,
        navio: navio ? navio.id : null,
        subProduto: subProduto.id,
        embalagem: embalagem.id,
      }
    },

    async handleFornecedorSelecionado(fornecedor) {
      if (!fornecedor) return
      await this.setOpcoesDoFornecedor(fornecedor)
    },

    async validate() {
      await this.$refs.observer.validate()
      if (this.pedido.quantidade < this.pedidoASerEditado.quantidade_usada)
        return false
      const isValid = this.$refs.observer.flags.valid
      return isValid
    },

    close() {
      this.$emit('close')
    },

    async save() {
      this.overlay = true
      if (await this.validate()) {
        const data = {
          ...this.pedido,
          fornecedor: this.pedido.fornecedor.public_id,
          cliente: this.pedido.cliente?.public_id,
          proprietario: this.empresaAtual.public_id,
          gate: this.empresaAtual.gate
            ? this.empresaAtual.public_id
            : this.pedido.gate.public_id,
          produto: this.pedido.produto.id,
        }
        delete data.to
        if (data.public_id) {
          this.pedidoASerEditado.quantidade_restante =
            this.pedido.quantidade - this.pedidoASerEditado.quantidade_usada
          data.quantidade_restante = this.pedidoASerEditado.quantidade_restante
          let pedido_a_editar = { ...data }
          delete pedido_a_editar.transportadora
          pedido_a_editar.terminal = data.terminal.public_id
          await this.editar(pedido_a_editar)
        }
        this.close()
      } else {
        this.errorMessage('Pedido com erro')
      }
      this.overlay = false
    },
    async editar(pedido) {
      try {
        await this.editPedido(pedido)
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },

    async handleSelectDistribuicao(_, { item: distribuicao }) {
      const { subProduto, embalagem, nPedidoImp } = distribuicao

      const produto = this.produtos.find(
        (produto) => produto.id === distribuicao.produto.id
      )

      this.pedido = Object.assign({}, this.pedido, {
        produto,
        subProduto: subProduto.id,
        embalagem: embalagem.id,
        numero_pedido_importacao: nPedidoImp,
        distribuicao: distribuicao.id,
      })

      this.showDistribuicoes = false
    },

    async getDistribuicoes() {
      const { navio } = this.pedido

      if (this.navioFoiSelecionado) {
        this.overlay = true

        setTimeout(async () => {
          this.distribuicoes = await DistribuicaoAPI.getDistribuicao(navio, {
            fornecedor: this.pedido.fornecedor.public_id,
          })

          this.showDistribuicoes = true
          this.overlay = false
        }, this.delay)
      }
    },
  },

  watch: {
    'pedido.produto': function (produto) {
      this.subProdutos = produto.subprodutos
    },
  },
}
</script>

<style>
.table__distribuicoes tr {
  cursor: pointer;
}
</style>
